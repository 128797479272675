$tile-heading-font-size-desktop: 2.25rem;
$primary-tile-heading-font-size-desktop: 3rem;
$primary-tile-height-mobile: 11.5rem;
$primary-tile-height-tablet: 20rem;
$primary-tile-height-desktop: 41.25rem;
$primary-tile-widget-height-desktop: 27rem;
$tile-height-mobile: 9.5rem;
$tile-height-tablet: 15rem;
$tile-height-desktop: 12.75rem;

.homepage-tiles__tile {
  @include make-col(12);

  position: relative;
  display: inline-block;
  overflow: hidden;

  .image-hover {
    border-radius: $border-radius-lg;
  }

  &.half-width {
    @include make-col(6);
  }
}

.homepage-tiles__tile-image {
  height: $tile-height-mobile;
  object-fit: cover;

  @include media-breakpoint-up(sm) {
    height: $tile-height-tablet;
  }

  @include media-breakpoint-up(lg) {
    height: $tile-height-desktop;
  }
}

.homepage-tiles__tile-image--prime {
  height: $primary-tile-height-mobile;
  object-fit: cover;

  @include media-breakpoint-up(sm) {
    height: $primary-tile-height-tablet;
  }

  @include media-breakpoint-up(lg) {
    height: $primary-tile-height-desktop;
  }
}

.homepage-tiles__tile-header,
.homepage-tiles__tile-header--prime {
  position: absolute;
  left: 1rem;
  right: 1rem;
  bottom: 0.75rem;
  color: $white;

  @include text-xx-large;

  &.half-width {
    @include text-x-large;
  }

  @include media-breakpoint-up(md) {
    @include text-xxx-large;

    left: 1.25rem;
    right: 1.25rem;
    bottom: 1rem;
  }

  @include media-breakpoint-up(lg) {
    left: 1.5rem;
    right: 1.5rem;
    bottom: 1rem;
    font-size: $tile-heading-font-size-desktop;
  }
}

.homepage-tiles__tile-header--prime {
  max-width: 70%;

  @include media-breakpoint-up(sm) {
    max-width: 65%;
  }

  @include media-breakpoint-up(md) {
    max-width: 62.5%;
  }

  h1 {
    @include text-xx-large;

    @include media-breakpoint-up(md) {
      @include text-xxx-large;
    }

    @include media-breakpoint-up(lg) {
      font-size: $tile-heading-font-size-desktop;
    }
  }
}

.homepage-tiles__primary-tile-brand-device {
  position: absolute;
  bottom: -1px;
  right: 0;
  height: 55%;
  width: auto;
  z-index: 1;
  pointer-events: none;
  border-bottom-right-radius: $border-radius-lg;
}

.homepage-tiles__cta-column {
  .homepage-tiles__tile {
    display: block;
  }

  .image-hover {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .homepage-tiles__tile-image--prime {
    @include media-breakpoint-up(lg) {
      height: $primary-tile-widget-height-desktop;
    }
  }
}
