.spa-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  & > div {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    // Set an approximate height before React filling in the content to reduce the Cumulative Layout Shift issue
    &:empty:first-of-type {
      height: 800px;
    }
  }
}
