.numeric-stepper {
  width: 6.5rem;

  &.decimal {
    width: 7.5rem;
  }

  .btn {
    background: $light;
    border-color: #e5e5e5;
  }
}
