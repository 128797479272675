.card__container--no-border {
  .card {
    border: 0 !important;
  }

  .card-body {
    padding-bottom: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .common-card__description {
    @include line-clamp(6);
  }

  .card-footer {
    padding-left: 0;
    padding-right: 0;
  }
}

.common-card__description {
  @include line-clamp(2);
}

.common-card__from-word {
  font-size: 0.875rem;
}

.common-card__price {
  font-size: 1.5rem;
}

.common-card__price-definition {
  font-size: 1.125rem;
}

.common-card__affiliate {
  font-size: 0.875rem;
  max-width: 6.5rem;
}

.common-card__affiliate-logo {
  height: 1.4rem;
}

.common-card--mini {
  .common-card__title {
    font-size: 1.125rem;

    @include line-clamp(2);
  }

  .common-card__description {
    display: none;
  }

  .common-card__from-word {
    font-size: 0.75rem;
  }

  .common-card__price {
    font-size: 1.125rem;
  }

  .common-card__price-definition {
    font-size: 0.75rem;
  }

  .common-card__button {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    border-radius: 0.2rem;
  }

  .common-card__affiliate {
    font-size: 0.75rem;
  }

  .common-card__affiliate-logo {
    max-width: 5rem;
  }
}
