.summary-of-changes {
  .summary-of-changes__icon {
    color: $tertiary;
  }

  .content__vertical-line {
    border-left: 1px dashed;
    width: 1px;
    height: 4rem;
    color: $primary;
  }

  .content__target-time {
    color: $success;
  }
}

.summary-of-changes--draft {
  .summary-of-changes__icon {
    width: 1.25rem;
  }

  .service__icon {
    &.invisible {
      // Change it to 1px height, so it won't take any vertical space
      height: 1px;
    }

    margin-right: 0.5rem !important;
  }
}
