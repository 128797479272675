.footer {
  background-color: $primary;
  color: $white;

  .accordion {
    @include media-breakpoint-down(lg) {
      > * + * {
        margin-top: 0.5rem;
      }
    }
  }

  .accordion-item {
    background-color: $primary;
    border: none;
  }

  .accordion-collapse {
    @include media-breakpoint-up(lg) {
      display: unset;
    }
  }

  a {
    color: $white;
  }
}

.footer__gradient-border {
  @include sealink-gradient(270deg);

  height: 0.5rem;
}

.footer__accordion-button {
  font-weight: $font-weight-bold;
  width: 100%;
  display: flex;
  justify-content: space-between;

  &::after {
    @include fa-icon;

    font: var(--fa-font-regular);
    content: "\f078";
    margin-left: auto;

    @include media-breakpoint-up(lg) {
      display: none;
    }
  }

  &:not(.collapsed)::after {
    content: "\f077";

    @include media-breakpoint-up(lg) {
      display: none;
    }
  }

  @include media-breakpoint-up(lg) {
    cursor: default;
    pointer-events: none;
  }
}

.footer__sub-nav-item {
  @include text-x-small;
}

.footer__divider {
  margin-top: none;

  @include media-breakpoint-up(lg) {
    display: none;
  }
}

.footer__contact {
  @include media-breakpoint-up(lg) {
    text-align: right;
  }
}

.footer__social-media {
  li {
    @include text-x-large;

    display: inline-block;
  }
}

.footer__acknowledgement {
  font-size: 0.75rem;

  figure {
    display: inline-block;
    margin-right: 0.5rem;
  }

  img {
    max-width: 2.25rem;
  }

  a {
    color: $grey;
  }
}
