.booking-summary {
  .card {
    border-color: $primary;
  }

  .service {
    .card {
      box-shadow: none;
    }
  }

  .card-header {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    background-color: $primary;
    color: $white;
  }

  .card-body--no-data {
    text-align: center;
    padding: 3rem 0;

    &.text-grey {
      padding: 2rem 0;

      a {
        display: inline-block;
        margin-top: 0.5rem;
      }
    }
  }

  .service__ellipse {
    font-size: 0.75rem;
    color: $primary;
  }

  .card-body__double-arrow {
    color: $tertiary;
  }

  .card-body__icon,
  .service__line {
    color: $primary;
  }

  .card-footer {
    display: flex;
    justify-content: space-between;
  }
}
