@charset "utf-8";

@import "settings";

/*** Vendor files ***/
@import "vendor/bootstrap";
@import "vendor/formie_base";
@import "global/base/resets";
@import "~@mobiscroll/react/dist/css/mobiscroll.react.min.css";
@import "~react-loading-skeleton/dist/skeleton";
@import "swiper/scss";
@import "swiper/scss/navigation";
@import "swiper/css/pagination";
@import "~tiny-slider/src/tiny-slider";
@import "~rc-slider/assets/index.css";

/**** Common mixins ****/
@import "design-system/mixins/globals";
@import "common/css/mixins/fa-icon";

/**** Common components ****/
@import "common/css/bt-footer";

/**** Mixins ****/
@import "mixins/mixins";

/**** Functions ****/
@import "design-system/functions/functions";

/**** Base ****/
@import "base/layout";
@import "base/typography";
@import "base/spacing";
@import "base/z-index";
@import "base/content";
@import "base/utilities";

/**** Components ****/
@import "design-system/components/promotional_banner";
@import "design-system/vendor/apple-pay";
@import "design-system/components/carousel";
@import "design-system/components/banner";
@import "design-system/components/nav";
@import "design-system/components/animations";
@import "design-system/ecom/booking-steps";
@import "design-system/components/salesforce";
@import "design-system/components/recaptcha";
@import "design-system/components/awards";
@import "design-system/ecom/pax";
@import "components/page-overlay-banner";
@import "components/site-switcher-form";
@import "components/containers";
@import "components/animations";
@import "components/breadcrumb";
@import "components/buttons";
@import "components/dropdown";
@import "design-system/components/input-with-icon";
@import "components/ecom-component-wrapper";
@import "components/header";
@import "components/payment-accordion";
@import "components/payment-submit";
@import "components/payment-saved-credit-card";
@import "design-system/ecom/checkout-form";
@import "components/toggle-button";
@import "components/toggle-switch";
@import "components/booking-landing";
@import "components/vertical-divider";
@import "components/sticky-summary";
@import "components/summary-modal";
@import "components/booking-login";
@import "components/image-hover";
@import "components/video";
@import "components/darken-overlay";
@import "components/gradient-overlay";
@import "components/receipt-documents";
@import "components/booking-summary";
@import "components/date-switcher";
@import "components/alerts";
@import "components/accordions";
@import "components/footer";
@import "components/journey-selector";
@import "components/item-selection";
@import "components/vehicle-form";
@import "components/vehicle-template";
@import "components/service";
@import "components/booking-contact-details";
@import "components/services-form";
@import "components/segment";
@import "components/booking-steps";
@import "components/dimensions-summary";
@import "components/loading-spinner";
@import "components/homepage-tiles";
@import "components/account-view";
@import "components/card";
@import "components/card-badge";
@import "components/card-footer";
@import "components/saved-payment-method";
@import "components/saved-vehicles";
@import "design-system/components/booking-history";
@import "components/grid-gallery";
@import "components/amount-to-pay";
@import "components/section-title";
@import "components/icon";
@import "components/property-card";
@import "components/experience-booking";
@import "components/booking-option-card";
@import "components/carousel-gallery";
@import "components/experience-category-card";
@import "components/widths";
@import "components/numeric_stepper";
@import "components/departures";
@import "components/zebra-stiping";
@import "components/carousel-general";
@import "components/primary-spacing";
@import "components/embeds";
@import "components/sidebar";
@import "components/category-cards-carousel";
@import "components/gdpr-notice";
@import "components/formie-form";
@import "components/subscribe-form";
@import "components/offcanvas";
@import "components/form";
@import "design-system/components/pagination";
@import "components/provider-description";
@import "components/spa-wrapper";
@import "components/backdrop";
@import "components/rc-slider";
@import "components/summary-of-changes";
@import "components/supported-credit-cards";
@import "components/express-careers";
@import "components/optional-extra";
@import "components/datepicker-preview";
@import "components/timetable";
@import "components/iframe-booking-modal";
@import "components/ticket_history";
@import "components/cancel-draft-editing";
@import "components/upcoming-booking";
@import "design-system/components/gift-card";

/**** Overrides ****/
@import "design-system/components/mobiscroll-overrides";
@import "components/mobiscroll-sealink";
