// ***********************
// Spacing system
// NOTE
// Alternativly Bootstrap recommends using the gap property in conjuction with d-grid.
// This will however change the childrens display property which you may not want.
// For example making inline elements full width.
// ***********************

@each $name, $unit in $spacers {
  // Vertical spacing
  .space-y-#{$name} {
    > * + * {
      margin-top: $unit;
    }
  }
  // Horizontal spacing
  .space-x-#{$name} {
    > * + * {
      margin-left: $unit;
    }
  }
}

// ***********************
// Spacing system with dividers
// ***********************

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $name, $unit in $spacers {
      // Vertical dividers
      .dy#{$infix}-#{$name},
      .divider-y#{$infix}-#{$name} {
        > * + * {
          padding-top: $unit;
          border-top: 1px solid $divider;
          margin-top: $unit;
        }
      }
      // Horizontal dividers
      .dx#{$infix}-#{$name},
      .divider-x#{$infix}-#{$name} {
        > * + * {
          padding-left: $unit;
          border-left: 1px solid $divider;
          margin-left: $unit;
        }
      }
    }
  }
}
