.accordion-button {
  &:not(.collapsed) {
    box-shadow: none;
    font-weight: 500;
    background-color: white;
  }

  &::after {
    transform-origin: 50% 30%;
  }
}

.accordion-body {
  padding-top: 0;
}

.accordion-item-wrapper {
  border-top: 1px solid $divider;

  &:first-child {
    border-top: none;

    .accordion__header {
      padding-top: 0;
    }
  }

  &:last-child {
    .accordion__header,
    .accordion-body {
      padding-bottom: 0;
    }

    .accordion-body {
      padding-top: 1.25rem;
    }
  }
}
