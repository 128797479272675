.service {
  &.service--behind {
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed;
  }

  &.clickable {
    cursor: pointer;

    .card {
      box-shadow: $default-shadow;

      &:hover {
        &:not(.selected, .unavailable) {
          background-color: tint-color($primary, 90%);
        }
      }
    }
  }

  .card {
    @include body-text;

    transition: background-color $default-timing ease;

    .card-body {
      padding: 0;
    }

    .service__content-top-wrapper {
      padding: 1rem;
    }

    &.selected {
      border: $success solid 1px;
      border-color: $success !important;
      background: tint-color($success, 90%);

      .service__line,
      .service__ellipse,
      .service__time {
        color: $success;
      }

      .service__data-top {
        .service__stop-info {
          .service__stop {
            color: $success;
          }

          .service__ellipse {
            background: $white;
            border-radius: 50%;
          }
        }
      }
    }

    &.unavailable,
    &.unavailable.selected {
      background: $light;
      border-color: $divider;

      .service__line,
      .service__ellipse,
      .service__stop {
        color: $text-grey;
      }

      .service__time {
        color: $black;
      }
    }

    .service__banner {
      @include text-x-small;

      display: inline-block;
      position: absolute;
      top: -0.75rem;
      color: $text-white;
      padding: 0.25rem 0.5rem;
      line-height: 1.25rem;
      border-radius: 0.25rem;

      &.sale__fare {
        background-color: $success;
      }

      &.connecting__service {
        background-color: $tertiary;
      }
    }

    .service__data-top {
      display: flex;
      justify-content: space-between;

      .service__stop-info {
        display: flex;

        .service__icon {
          &.unavailable {
            color: $text-grey;
          }

          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          align-content: center;
          color: $secondary;
          margin-right: 0.75rem;

          .service__line {
            border-left: 1px dashed;
            width: 1px;
            height: 2.5rem;
          }
        }

        .service__time {
          @include text-large;

          font-weight: 500;
        }

        .service__stop {
          &.unavailable {
            color: $text-grey;
          }

          color: $primary;
          font-weight: 400;
          position: relative;
          top: -0.375rem;
        }
      }

      .service__availability {
        width: 5.25rem;
        border-radius: 0.25rem;
        padding: 0.25rem;
      }

      .service__label {
        color: $text-grey;
        text-align: right;
      }

      .service__price-buttons {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        margin-left: 2rem;

        .btn {
          &.selected {
            background-color: $success;
          }

          &.unavailable {
            background-color: $text-grey;
            cursor: auto;
          }

          display: flex;
          align-items: center;
          background-color: $primary;
          color: $text-white;
          text-transform: capitalize;
          letter-spacing: normal;

          .service__price {
            @include body-text;

            color: $text-white;
            font-weight: 500;
          }
        }

        .collapse__button {
          @include text-x-small;

          padding: 0.5rem 0;
          text-align: right;
          font-weight: 400;

          &:hover {
            cursor: pointer;
          }
        }

        .collapse__button-text {
          font-size: 1rem;
          width: 5rem;
        }
      }
    }

    .service__fare {
      background-color: $light;
      border-bottom-left-radius: 1px;
      border-bottom-right-radius: 1px;

      .service__fare-container {
        padding: 1rem;
      }

      .service__fare-title {
        display: flex;
        justify-content: space-between;

        .service__fare-type {
          font-weight: 700;
        }

        .service__fare-price {
          font-weight: 500;
        }
      }

      .service__fare-item {
        font-weight: 400;
      }
    }

    .service__ellipse {
      font-size: 0.75rem;
    }

    .service__line,
    .service__ellipse {
      color: $primary;
    }
  }
}
