.booking-steps {
  position: relative;
  z-index: 0;
  overflow-x: auto;
  margin: 0 -1rem;

  .booking-steps__step {
    min-width: 5.1rem;
    flex: 1 0 0%;
  }

  .booking-steps__name {
    @include text-x-small;

    line-height: 1.2;
    color: $grey;
  }

  .booking-steps__dot {
    i {
      font-size: 1.5rem;
    }

    &::before,
    &::after {
      background-color: $divider;
    }
  }

  .fas {
    &.fa-check-circle {
      color: $success;
    }

    &.fa-circle {
      color: $divider;
    }
  }

  .far {
    &.fa-circle {
      font-weight: 100;
      color: $primary;
    }
  }
}

@include media-breakpoint-down(sm) {
  .booking-steps {
    mask-image: linear-gradient(to right, transparent 0%, #000 10%, #000 90%, transparent 100%);
  }
}
