@use "sass:map";

.item-selection {
  .item-selection__title {
    line-height: 1;
    padding-right: 1rem;

    label {
      font-weight: 500;
      display: block;
      color: $black;
    }
  }

  .item-selection__stepper {
    min-width: 8.25rem;

    .button--input-group {
      background-color: $light;
      color: $black;
      width: 2.75rem;
      height: 2.75rem;
      border: 1px solid map.get($custom-colors, grey-border);
    }

    .form-control {
      max-width: 2.75rem;
      border-radius: 0;
      color: $black;
    }

    .form-control--sans-spinner {
      border-color: map.get($custom-colors, grey-border);
    }
  }

  .input-group-prepend {
    .button--input-group {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .input-group-append {
    .button--input-group {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  .button--input-group {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.item-selection--passenger-details {
  .item-selection__stepper {
    min-width: unset;
  }

  .form-select {
    @include media-breakpoint-down(md) {
      padding-left: 0.25rem;
      padding-right: 0.25rem;
      background-position: right 0.25rem center;
    }
  }
}
