.dimensions-summary {
  .dimensions-summary__figure {
    max-width: 20rem;
    padding: 0 1rem 0 0;
    margin: auto;

    @include media-breakpoint-up(sm) {
      max-width: 25rem;
    }

    .fal {
      @include text-large;

      color: $grey;
    }
  }

  .dimensions-summary__vertical-arrow {
    width: 3rem;
    height: 6rem;

    .dimensions-summary__arrow-container {
      padding-top: 2px;

      i:first-child {
        top: -0.5rem;
      }

      i:last-child {
        bottom: -0.5rem;
      }
    }

    .dimensions-summary__vertical-line {
      border-left: 0.075rem solid $grey;
      height: 6rem;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .dimensions-summary__horizontal-arrow {
    .dimensions-summary__arrow-container {
      i:first-child {
        left: -2px;
      }

      i:last-child {
        right: -2px;
      }
    }

    .dimensions-summary__horizontal-line {
      border-top: 1px solid $grey;
      top: 0.5rem;
    }
  }

  .dimensions-summary__in-metre-value {
    background: $white;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 0 0.5rem;
    color: $primary;
  }
}
