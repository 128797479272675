.service-form {
  h2 > i {
    color: $primary;
  }

  .services-form__button-group {
    display: flex;
    flex-direction: column;

    .btn-accent {
      background-color: $accent;
      color: $white;
    }
  }

  .service-form__reference {
    display: flex;
    justify-content: center;
    margin-top: 1.25rem;
    color: $grey;
  }

  .service-form__double-arrow {
    color: $tertiary;
  }
}
