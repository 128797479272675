// Buttons

.btn {
  font-weight: 500;
  text-decoration: none;

  &.button-link {
    text-decoration: underline;
    font-weight: 400;
  }

  &.btn-success,
  &.btn-danger {
    color: $white;
  }

  &.btn-muted {
    background-color: $grey;
    color: $white;
  }

  &:focus {
    box-shadow: none;
  }
}

.floating-mobile-btn {
  position: fixed;
  z-index: 10;
  left: 50%;
  transform: translateX(-50%);
  bottom: 20px;
}

.btn-circle-xmark {
  position: absolute;
  right: -1rem;
  top: -0.75rem;
}

.btn-text-primary-hover {
  &:hover {
    color: $primary;
  }
}

.btn-outline-success:active,
.btn-outline-danger:active {
  --bs-btn-active-color: #fff;
}

.btn-outline-success:hover,
.btn-outline-danger:hover {
  --bs-btn-hover-color: #fff;
}
