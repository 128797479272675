.carousel-gallery {
  position: relative;
  color: $text-white;

  .swiper-button-prev,
  .swiper-button-next {
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 50%;
    background-color: rgba($black, 0.75);

    &::after {
      color: #fff;
      font-size: 1rem;
    }
  }

  .pagination-custom-background {
    border-radius: 4px;
    background-color: rgba($black, 0.75);
  }

  .swiper-pagination-custom {
    bottom: 1rem;
  }

  .swiper {
    cursor: pointer;
  }
}

.pswp__img {
  object-fit: cover;
}

.pswp__custom-caption {
  font-size: 1rem;
  padding: 0.5rem 1rem;
  background-color: rgba($black, 0.75);
}
