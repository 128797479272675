html,
body {
  height: 100%;
}

.main-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}
