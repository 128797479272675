.horizontal-divider {
  border-bottom: 1px solid $divider;
}

// ***********************
// Borders
// ***********************

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .border#{$infix} {
      border: $border-width solid $border-color !important;
    }

    .border#{$infix}-top {
      border-top: $border-width solid $border-color !important;
    }

    .border#{$infix}-right {
      border-right: $border-width solid $border-color !important;
    }

    .border#{$infix}-bottom {
      border-bottom: $border-width solid $border-color !important;
    }

    .border#{$infix}-left {
      border-left: $border-width solid $border-color !important;
    }

    .border#{$infix}-0 {
      border: 0 !important;
    }

    .border#{$infix}-top-0 {
      border-top: 0 !important;
    }

    .border#{$infix}-right-0 {
      border-right: 0 !important;
    }

    .border#{$infix}-bottom-0 {
      border-bottom: 0 !important;
    }

    .border#{$infix}-left-0 {
      border-left: 0 !important;
    }
  }
}

// ***********************
// Box Shadow
// ***********************

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .shadow#{$infix}-none {
      box-shadow: none !important;
    }

    .shadow#{$infix}-sm {
      box-shadow: $box-shadow-sm !important;
    }

    .shadow#{$infix}-reg {
      box-shadow: $box-shadow !important;
    }

    .shadow#{$infix}-lg {
      box-shadow: $box-shadow-lg !important;
    }

    .shadow#{$infix}-inset {
      box-shadow: $box-shadow-inset !important;
    }
  }
}

// ***********************
// Overflow
//
// direction-specific overflow utiltiies absent from core Bootstrap
// e.g. .overflow-vertical-scroll
// ***********************

$overflowDirections: (
  horizontal: x,
  vertical: y,
);

$overflowValues: (
  visible,
  hidden,
  scroll,
  auto,
);

@each $directionName, $directionValue in $overflowDirections {
  @each $overflowValue in $overflowValues {
    .overflow-#{$directionName}-#{$overflowValue} {
      overflow-#{$directionValue}: #{$overflowValue};
    }
  }
}

// ***********************
// Object-fit
// ***********************

$objectFitValues: (
  fill,
  contain,
  cover,
  none,
  scale-down,
);

@each $objectFitValue in $objectFitValues {
  .object-fit-#{$objectFitValue} {
    object-fit: #{$objectFitValue};
  }
}
