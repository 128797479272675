.datepicker-preview {
  .service__availability {
    border-radius: 0.25rem;
    flex-basis: 50%;
    padding: 0.25rem;
  }
}

.datepicker-preview__calendar-holder {
  max-width: 31rem;
}

.datepicker-preview__time {
  color: $black;
  font-weight: $font-weight-bold;
}

.datepicker-preview__period-title {
  font-weight: 700;
}

.datepicker-preview__service-box {
  cursor: pointer;

  &:hover {
    transform: scale(1.05);
  }
}

.datepicker-preview__service-box--disabled {
  pointer-events: none;
}

.datepicker-preview__service-box--selected {
  background: tint-color($success, 90%);
}

.datepicker-preview__right-block {
  a {
    color: $grey;
  }
}

.datepicker-preview__no-service {
  width: 6rem;
}

@include media-breakpoint-down(lg) {
  .datepicker-preview__right-block {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 21.5rem;
  }
}

@include media-breakpoint-up(lg) {
  .datepicker-preview__calendar-holder {
    .mbsc-datepicker {
      position: absolute;
      left: 1rem;
      top: 0.5rem;
    }
  }

  .datepicker-preview__right-block {
    margin-left: 19.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 24rem;
    min-width: 40.5rem;
  }

  .datepicker-preview__service-skeleton {
    width: 12rem;
    height: 4.5rem;
  }
}
