.saved-vehicles {
  .saved-vehicles__card {
    border: solid 1px $divider;
    border-radius: $border-radius;
    padding: 1rem;

    .saved-vehicles__detail {
      p:first-of-type {
        font-weight: 500;
      }
    }

    .button-link {
      @include text-x-small;

      padding: 0;
      margin-top: 1rem;
      color: $danger;
    }
  }
}
