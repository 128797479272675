.card-badge__container {
  position: relative;

  .fa-location-dot {
    color: $primary;
  }
}

.card-badge__badge {
  @include text-x-small;

  position: absolute;
  top: 0;
  right: 1.5rem;
  line-height: 1.5rem;
  color: $white;
  background-color: $tertiary;
  padding: 0 0.5rem;
  border-radius: 0 0 $border-radius $border-radius;
  box-shadow: $default-shadow;
}

.card-badge__badges {
  @include text-x-small;

  position: absolute;
  top: 0;
  right: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
