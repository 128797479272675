@use "sass:math";

@mixin sealink-gradient ($deg) {
  background: linear-gradient($deg, $primary 0%, $tertiary 100%);
}

@mixin full-wh-object($object-fit: "") {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  @if $object-fit != "" {
    object-fit: $object-fit;
  }
}

@mixin list-reset {
  list-style-type: initial;
  list-style-position: outside;
  padding-left: 2rem;
}

@mixin ratio-max-height($height, $width) {
  max-height: math.div($height, $width) * 100vw;
}

@mixin aspect-ratio($height, $width) {
  padding-top: math.div($height, $width) * 100%;
}
