.form-control--sans-spinner[type="number"] {
  -moz-appearance: textfield;

  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.form-control--braintree-same-height {
  height: 2.5rem;
}
