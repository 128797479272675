.mobiscroll-sealink {
  .mbsc-selected .mbsc-calendar-cell-text {
    background: $primary;
    border-color: $primary;
  }

  .mbsc-calendar-button.mbsc-button,
  .mbsc-calendar-today {
    color: $primary;
  }

  .mbsc-range-day::after {
    background-color: tint-color($primary, 75%);
  }

  .mbsc-calendar-label-text {
    text-align: center;
    text-overflow: unset;
  }

  .mbsc-blurred {
    .mbsc-calendar-label-text {
      @extend .react-loading-skeleton;
    }
  }
}
