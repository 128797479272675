.category-cards-carousel-container {
  aspect-ratio: 1/1;

  @include media-breakpoint-up(sm) {
    aspect-ratio: 3.6/1;
  }
}

.category-cards-carousel {
  position: relative;

  .nav-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 2rem;
    z-index: 10;
    transition: all $default-timing;

    &:hover {
      cursor: pointer;
    }
  }

  .category-cards-carousel__previous-button {
    @extend .nav-button;

    left: -2.5rem;
  }

  .category-cards-carousel__next-button {
    @extend .nav-button;

    right: -2.5rem;
  }
}
