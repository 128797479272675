.fui-hidden {
  display: none
}

.fui-sr-only,[data-fui-sr-only] {
  clip: rect(0,0,0,0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  position: absolute;
  width: 1px
}

.fui-field {
  flex: 1
}

.fui-field[data-conditionally-hidden] {
  display: none
}

.fui-label-left-input .fui-field-container,.fui-label-right-input .fui-field-container {
  align-items: baseline;
  display: flex
}

.fui-label-left-input .fui-input-container,.fui-label-right-input .fui-input-container {
  flex: 1
}

.fui-label-below-input>.fui-field-container>.fui-input-container>.fui-fieldset>.fui-legend {
  clear: both;
  display: block;
  float: left;
  width: 100%
}

.fui-layout-horizontal .fui-layout-wrap,.fui-row {
  display: flex;
  flex-wrap: wrap
}

[data-fui-page-hidden] {
  display: none
}

.fui-btn-container,.fui-btn-wrapper {
  display: flex;
  justify-content: flex-start
}

.fui-btn-container.fui-btn-right,.fui-btn-wrapper.fui-btn-right {
  justify-content: flex-end
}

.fui-btn-container.fui-btn-center,.fui-btn-container.fui-btn-center-save-left,.fui-btn-container.fui-btn-center-save-right,.fui-btn-wrapper.fui-btn-center,.fui-btn-wrapper.fui-btn-center-save-left,.fui-btn-wrapper.fui-btn-center-save-right {
  justify-content: center
}

.fui-btn-container.fui-btn-left-right,.fui-btn-container.fui-btn-save-left,.fui-btn-container.fui-btn-save-right,.fui-btn-wrapper.fui-btn-left-right,.fui-btn-wrapper.fui-btn-save-left,.fui-btn-wrapper.fui-btn-save-right {
  justify-content: space-between
}

.fui-btn-container.fui-btn-right-save-left,.fui-btn-wrapper.fui-btn-right-save-left {
  justify-content: flex-start
}

.fui-btn-container.fui-btn-center-save-left,.fui-btn-container.fui-btn-right-save-left,.fui-btn-container.fui-btn-save-left,.fui-btn-wrapper.fui-btn-center-save-left,.fui-btn-wrapper.fui-btn-right-save-left,.fui-btn-wrapper.fui-btn-save-left {
  flex-direction: row-reverse
}

.fui-btn-container .fui-btn[data-conditionally-hidden],.fui-btn-wrapper .fui-btn[data-conditionally-hidden] {
  display: none
}

.fui-save,.fui-submit {
  order: 10
}

.fui-btn * {
  pointer-events: none
}
