.iframe-booking-modal {
  .modal-dialog {
    @include media-breakpoint-up(sm) {
      height: 90vh;
    }
  }

  .modal-content {
    overflow: hidden;
    height: 100%;
  }
}

.iframe-booking-modal__external {
  width: 100%;
  height: 100%;
  border: none;
}
