.formie-form__wrapper {
  // Labels and legends

  .fui-field-container {
    margin-bottom: 1.5rem;
  }

  .fui-label {
    @extend .form-label;
  }

  .fui-legend {
    @extend .form-label;
  }

  // Inputs

  .fui-input:not(.fui-checkbox-input):not(.fui-radio-input) {
    @extend .form-control;
  }

  .fui-select {
    @extend .form-select;

    overflow-x: hidden;
  }

  .fui-checkbox {
    @extend .form-check;

    .fui-checkbox-input {
      @extend .form-check-input;
    }

    .fui-checkbox-label {
      @extend .form-check-label;
    }
  }

  .fui-radio {
    @extend .form-check;

    .fui-radio-input {
      @extend .form-check-input;
    }

    .fui-radio-label {
      @extend .form-check-label;
    }
  }

  textarea.fui-input {
    min-height: 7rem;
    resize: vertical;
  }

  // Buttons

  .fui-btn {
    @extend .btn, .btn-light;
  }

  .fui-btn.fui-submit {
    @extend .btn, .btn-primary;

    width: 100%;
  }

  .fui-btn-container {
    margin-top: 0.5rem;
  }

  // Alerts and errors

  .fui-required {
    color: $danger;
  }

  .fui-alert {
    @extend .alert;
  }

  .fui-alert-error {
    @extend .alert-danger;

    &:has(> p:empty) {
      display: none;
    }

    &:empty {
      display: none;
    }
  }

  .fui-error-message {
    color: $danger;

    @include text-x-small;

    margin-top: 0.25rem;
  }

  // Misc

  .fui-hidden {
    display: none;
  }

  .fui-row {
    gap: 1rem;
  }
}

.fui-type-address {
  .fui-field-rows {
    display: grid;
    gap: 1rem;
  }
}

.fui-layout-wrap {
  clear: both; // fixes an issue with checkbox floats
}

.flatpickr-calendar.open {
  right: auto !important;
}
