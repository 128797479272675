.carousel__mobile-container {
  @extend .container-xxl;

  @include media-breakpoint-down(md) {
    padding: 0;
  }

  .swiper-slide {
    height: auto;
  }

  &.with-border {
    border-top: 1px solid $divider;
  }
}
