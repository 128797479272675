.dropdown-toggle::after {
  @include fa-icon;

  font: var(--fa-font-regular);
  content: "\f078";
  border: none;
  vertical-align: 0;
  margin-left: 0.5em;
}

.dropdown-menu {
  box-shadow: $default-shadow;
}
