.booking-landing {
  .booking-landing__item {
    @include text-large;

    cursor: pointer;
    border: 1px dashed $tertiary;
    border-radius: $border-radius * 2;
    text-align: center;
    color: $primary;
    background-color: $white;
    transition: background-color $default-timing ease;

    &:hover {
      &:not(.booking-landing__item--added, .booking-landing__item--included) {
        background-color: tint-color($primary, 90%);
      }
    }
  }

  .booking-landing__item--added {
    @include sealink-gradient(144deg);

    border: 1px solid $primary;
    font-size: 1rem;
    text-align: left;
    color: $white;
    cursor: initial;
    position: relative;
  }

  .booking-landing__item--included {
    border: 1px dashed $grey;
    color: $grey;
    background-color: $light;
    position: relative;
    cursor: initial;
  }

  .booking-landing__item-title {
    margin-left: 0.25em;
  }

  .booking-landing__item-main-icon {
    width: 2.5rem;
  }

  .booking-landing__item-check-icon {
    font-size: 1.25rem;
  }

  .booking-landing__item-check-icon--included {
    color: $success;
    font-size: 1.75rem;
    position: absolute;
    right: 0.75rem;
    top: 0.75rem;
  }

  .booking-landing__item-ferry-icon {
    width: 7rem;
  }

  .booking-landing__added-icon {
    position: absolute;
    right: 1.5rem;
    z-index: 0;
  }

  .booking-landing__ferry-icon {
    width: 8rem;
  }

  .booking-landing__accommodation-icon {
    width: 4rem;
  }

  .booking-landing__experience-icon {
    width: 4rem;
  }

  .booking-landing__item-button {
    color: $white;
    border-color: $white;
  }

  .booking-landing__booking-reference {
    color: $grey;
  }

  .booking-landing__cross-sell {
    border: 1px dashed $tertiary;
    border-radius: $border-radius * 2;
  }

  .card__description {
    @include line-clamp(2);
  }
}

.booking-landing__cross-sell {
  .swiper-button-prev,
  .swiper-button-next {
    height: 4.3rem;
    background-color: $white;
    opacity: 1;

    &::after {
      color: $black;
      font-size: 1.6rem;
    }
  }

  .swiper-button-prev {
    left: -2.2rem;
  }

  .swiper-button-next {
    right: -2.2rem;
  }

  .swiper-button-disabled {
    &::after {
      color: $grey;
    }
  }
}
