$form-check-input-width: 1.5em;

.payment_accordion {
  border: 1px solid $divider;
  box-sizing: border-box;
  border-radius: 3px;
  margin-bottom: 1em;

  .payment_accordion__button {
    width: 100%;
    border: none;
    outline: none;
    text-align: left;
    cursor: pointer;
    transition: background-color 0.3s linear;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem;
  }

  .payment_accordion__button-title {
    align-items: center;
  }

  .fa-gift-card {
    width: 20px;
  }

  .form-switch {
    padding-left: 2em;
    margin-right: -0.4em;
  }

  .form-check {
    .form-check-input {
      width: $form-check-input-width;
      height: $form-check-input-width;
      border-radius: $form-check-input-width;
      background-color: $white;
      border-color: $divider;

      &:checked {
        background-color: $white;
        background-image: $form-check-input-checked-bg-image;
      }
    }
  }

  &.open {
    border-color: $primary;

    .form-check {
      .form-check-input {
        border-color: $primary;
        background-image: url("../images/radio-active.svg");
      }
    }
  }

  .payment_accordion__content {
    padding: 1rem;

    &:empty {
      padding: 0;
    }
  }

  .img-apple-pay {
    display: inline;
    width: 50px;
  }
}
