.property-card__images {
  width: 100%;

  @include media-breakpoint-up(md) {
    width: 300px;
    height: 200px;

    .property-card__simple & {
      width: 100%;
    }
  }

  .card-img {
    border-radius: 0;
    object-fit: cover;
    user-select: none;
    height: 100%;

    @include media-breakpoint-up(md) {
      width: 300px;
      height: 200px;
    }
  }
}

.property-card__booking-details {
  border-top: 1px solid $divider;

  @include media-breakpoint-up(md) {
    border-top: 0;
    border-left: 1px solid $divider;
  }
}

.property-card {
  .swiper-slide {
    aspect-ratio: 3/2;

    @include media-breakpoint-up(md) {
      max-height: 200px;
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    width: 40px;
    height: 40px;
    margin-top: -20px;
    border-radius: 20px;
    background-color: rgba(0, 0, 0, 0.5);

    &::after {
      color: #fff;
      font-size: 1rem;
    }
  }

  &.cursor-pointer {
    cursor: pointer;
  }

  a {
    display: flex;
    color: $body-color;
    text-decoration: none;
    height: 100%;

    @include media-breakpoint-up(md) {
      aspect-ratio: 3/2;
      width: 300px;
      max-height: 200px;
    }

    &:hover {
      text-decoration: none;
      color: $body-color;
    }
  }
}
