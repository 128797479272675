.header__main {
  background-color: $white;
  border-bottom: 1px solid $divider;
}

.header__main-container {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  background-color: $primary;
}

.header__nav {
  border-bottom: 1px solid $divider;
}

.header__sealink-logo {
  width: 100%;
  height: auto;
  max-width: 10rem;

  @include media-breakpoint-up(lg) {
    max-width: 10.5rem;
  }

  @include media-breakpoint-up(xl) {
    max-width: 14rem;
  }
}

.header__top {
  background-color: $bt-charcoal;
}

.header__bt-logo {
  max-width: 12.5rem;
  height: 1.75rem;
}

.header-desktop__nav-item {
  display: flex;
}

.header-desktop__nav-item--top-level {
  display: flex;
  align-items: center;
  color: $white;
  border-bottom: solid 0.25rem transparent;
  font-weight: $font-weight-normal;
  line-height: 1.25;

  @include media-breakpoint-up(xxl) {
    @include text-large;
  }

  &.active,
  &:hover {
    border-bottom-color: $accent;
    color: $white;
  }

  &::after {
    @include text-x-small;

    margin-left: 0.5rem;
  }

  &.show {
    &::after {
      transform: rotate(180deg);
    }
  }
}

.header-desktop__subnav-link {
  @include text-large;

  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.header__dropdown-menu {
  border-radius: 0 0 0.25rem 0.25rem;
  padding: 2rem 1.5rem;
  box-shadow: $large-shadow;
  border-width: 0;
}

.header__nav-link {
  color: $black;

  &.active,
  &:hover {
    color: $primary;
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}

.header__mobile-menu {
  z-index: 1;
}

.header__nav-wrapper--desktop {
  width: 100%;

  @include media-breakpoint-up(xxl) {
    max-width: calc(100% - 16rem);
  }
}

.header__icons {
  display: flex;
  align-items: center;
  height: 100%;
}

.header__icon {
  position: relative;
  padding: 0.5rem;
  transition: opacity $default-timing-fast;

  &:hover {
    opacity: 0.75;
  }
}

.header__icon-dropdown {
  .dropdown-toggle {
    &::after {
      content: none;
    }
  }

  .dropdown-item-text {
    color: $text-grey;
    padding: 0 1rem;
  }

  .dropdown-menu.show {
    width: max-content;
  }

  .dropdown-divider {
    opacity: 1;
  }
}

.header__icon-cart {
  position: relative;

  &::after {
    position: absolute;
    top: 0.4em;
    right: 0.25em;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    background-color: $accent;
    content: "";
  }
}

.header__fa-stack {
  position: absolute;
  right: 0.5rem;
  font-size: 0.5rem;
  height: 0.5rem;
  width: 0.5rem;
  line-height: 1;
}

.header__icon-circle {
  color: $white;
}

.header__icon-check {
  color: $accent;
}

.header__icon-menu {
  display: flex;
  align-items: center;
  height: 100%;
  margin-right: 1rem;

  i {
    font-size: 1.375rem;
    color: $white;

    &:hover {
      opacity: 0.75;
    }
  }
}

.header__modal-dialog--search {
  .modal-content {
    background-color: transparent;
  }
}

.header__modal-dialog--search__backdrop {
  z-index: $zindex-offcanvas;
}

.nav-mobile,
.nav-mobile__subnav {
  background: transparent;

  a,
  button {
    color: $white;
    padding: 0;
    border-bottom: none;
    text-align: left;
    text-decoration: none;

    &:hover {
      color: $white;
    }
  }
}

.nav-mobile__header {
  align-items: center;

  .btn-close {
    margin: 0;
    margin-left: auto;
    opacity: 1;
  }
}

.nav-mobile__title {
  color: $white;
  border-bottom: 4px solid $accent;
  display: inline-block;
}
