.ecom-component {
  &.sealink-ecom-wrapper {
    padding: 0 !important;

    textarea {
      &.form-control {
        min-height: 8rem;
      }
    }
  }
}
