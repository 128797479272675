@use "sass:map";

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

$theme-colors: map.merge($theme-colors, $custom-colors);

@import "~bootstrap/scss/maps";

$spacers: map.merge($spacers, $custom-spacers);
$gutters: map.merge($gutters, $custom-gutters);
$grid-breakpoints: map.merge($grid-breakpoints, $custom-breakpoints);

@import "~bootstrap/scss/utilities";

$utilities: map.merge(
  $utilities,
  (
    "opacity": (
      property: opacity,
      values: (
        0: 0,
        25: 0.25,
        50: 0.5,
        75: 0.75,
        98: 0.98,
        100: 1,
      )
    ),
    "width": map.merge(
      map.get($utilities, "width"),
      (responsive: true),
    ),
    "flex-basis": (
      property: flex-basis,
      class: flex,
      values: (
        basis-0: 0,
      )
    ),
    "cursor": (
      property: cursor,
      values: pointer not-allowed,
    ),
    "height": map.merge(
      map.get($utilities, "height"),
      (
        values: map.merge(
          map.get(map.get($utilities, "height"), "values"),
          (fit-content: fit-content),
        ),
      ),
    ),
    "font-weight": map.merge(
      map.get($utilities, "font-weight"),
      (
        values: map.merge(
          map.get(map.get($utilities, "font-weight"), "values"),
          (500: 500),
        ),
      ),
    ),
    "font-size": map.merge(
      map.get($utilities, "font-size"),
      (
        values: map.merge(
          map.get(map.get($utilities, "font-size"), "values"),
          (7: 0.875rem),
        ),
      ),
    ),
  )
);

// Layout & components
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/nav";
// @import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/accordion";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/alert";
// @import '~bootstrap/scss/progress';
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
// @import '~bootstrap/scss/toasts';
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
// @import "~bootstrap/scss/popover";
// @import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/spinners";
@import "~bootstrap/scss/offcanvas";

// Helpers
@import "~bootstrap/scss/helpers";

// Utilities
@import "~bootstrap/scss/utilities/api";
// scss-docs-end import-stack
