.journey_selector {
  .journey_selector__left-wrapper {
    padding: 1rem;
  }

  .journey_selector__illustration {
    .journey_selector__line {
      position: relative;
      border-left: 1px dashed $primary;
      height: 3rem;
      margin-right: 1.75em;
      margin-left: 0.5em;

      &::before,
      &::after {
        content: "";
        width: 11px;
        height: 11px;
        border-radius: 50%;
        position: absolute;
        left: -5.5px;
      }

      &::before {
        background-color: $primary;
        top: -0.75rem;
      }

      &::after {
        border: 1px solid $primary;
        bottom: -0.75rem;
      }
    }
  }

  .journey_selector__routes {
    label,
    span {
      color: $black;
      font-weight: 500;
    }

    label {
      color: $text-grey;
    }
  }

  .journey_selector__button {
    width: 7rem;

    @include media-breakpoint-up(sm) {
      width: 8rem;
    }

    &:focus {
      box-shadow: none;
    }
  }

  .journey_selector__swap-icon {
    color: $black;
    font-size: 2rem;
    font-weight: 700;
    transform: rotate(90deg);
  }

  .journey-selector__multi-swap-btn {
    width: 5rem;
  }
}
