@use "sass:map";
$form-check-input-height: 1.5em;

.toggle-switch {
  .form-switch {
    .form-check-input {
      width: $form-check-input-height * 1.75;
      height: $form-check-input-height;
      cursor: pointer;
      -webkit-appearance: none;
      border: 1px solid map.get($custom-colors, grey-border);
      background-color: map.get($custom-colors, grey-border);
      background-image: url("../images/toggle-white.svg");

      &:checked {
        border-color: $primary;
        background-color: $primary !important;
      }
    }
  }
}
