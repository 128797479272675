.loading-spinner {
  color: $text-grey;

  &.light {
    color: $white;

    i:first-of-type {
      color: $white;
    }
  }

  i:first-of-type {
    color: $primary;
  }
}
