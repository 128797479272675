.site-switcher-form__container {
  border: 1px solid $white;
}

.site-switcher-form__select {
  border: none;
  appearance: none;
  outline: none;
  color: $white;
  cursor: pointer;
  line-height: 3.5rem;
}

.site-switcher-form {
  width: 20.6rem;

  @include text-x-large;

  @include media-breakpoint-up(lg) {
    width: 27rem;

    .site-switcher-form__select {
      line-height: 4.8rem;
    }

    @include text-xx-large;
  }
}

.site-switcher__arrow {
  position: absolute;
  top: -0.8rem;
  right: 2rem;
  width: 0;
  height: 0;
  border-top: 1rem solid $white;
  border-left: 1.125rem solid transparent;
  border-right: 1.125rem solid transparent;
  transform: rotateZ(180deg);
}

.site-switcher__header {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.site-switcher__group {
  padding: 0.75rem 1rem;
}

.site-switcher__item {
  overflow: hidden;
  white-space: nowrap;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;

  a {
    &:hover {
      color: $primary !important;
    }
  }
}

.site-switcher {
  width: 21.25rem;
}

.site-switcher--nav-tab {
  width: 22.5rem;
}

.site-switcher--above-banner {
  width: 100%;
}

@include media-breakpoint-up(lg) {
  .site-switcher--above-banner {
    font-size: 1rem;
  }
}

.fa-arrow-up-right-from-square {
  font-size: 0.5rem;
  vertical-align: text-top;
}
