@use "sass:math";

$container-width: 1320px;
$x-narrow: $container-width * math.div(6, 12);
$narrow: $container-width * math.div(8, 12);
$medium: $container-width * math.div(10, 12);

// Centered containers
@mixin container($max-width) {
  width: 100%;
  max-width: $max-width;
  padding-left: $container-padding-x;
  padding-right: $container-padding-x;
  margin-left: auto;
  margin-right: auto;
}

.container--x-narrow {
  @include container($x-narrow);
}

.container--narrow {
  @include container($narrow);
}

.container--medium {
  @include container($medium);
}
