.date-switcher {
  .date-switcher__current-date {
    border-bottom: solid $tab-border-width $primary;
  }

  .date-switcher__date-button--not-disabled {
    cursor: not-allowed;
  }

  .date-switcher__date-picker {
    position: relative;

    .date-switcher__calendar-icon {
      color: $primary;
    }
  }

  .date-switcher__date-picker-field {
    width: 1.75rem;
    height: 2rem;
    position: absolute;
    top: 0;
    left: 0;
  }
}
