.account-view {
  background-color: $light;
}

.account-view__header {
  background-color: $white;
}

.account-view__container {
  h5 {
    margin-bottom: 1.25rem;
  }

  > * {
    padding: 1.5rem;
    border: $divider solid 1px;
    box-shadow: $box-shadow-sm;
    border-radius: $border-radius;
    background-color: $white;
  }
}
