.promotional-banner__wrapper {
  // Improve the Cumulative Layout Shift(CLS) performance
  aspect-ratio: 1/1;

  @media screen and (min-width: 801px) {
    aspect-ratio: 3.4/1;
  }

  & > ul > li:not(:first-child) {
    display: none;
  }

  .tns-nav {
    display: flex;
    position: absolute;
    width: 100%;
    top: 0;
    text-align: right;
    padding: 1.5rem;
    justify-content: flex-end;
  }

  .tns-nav > button {
    display: block;
    width: 0.75rem;
    height: 0.75rem;
    border: 1px solid $white;
    border-radius: 0.375rem;
    margin-left: 0.5rem;
  }

  .tns-nav-active {
    background-color: $white;
  }
}

.promotional-banner__mask {
  background: linear-gradient(to right, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0));
}
