.vehicle-template__item {
  cursor: pointer;

  &:hover {
    background-color: $light;
  }

  &.vehicle-template__item--disabled {
    opacity: 0.5;
    cursor: not-allowed;

    &.vehicle-template__item--selected {
      opacity: 1;
      cursor: pointer;

      .form-check-input {
        opacity: 1;
      }
    }
  }
}
