$section-title-line-height: 3px;
$section-title-line-width: 3rem;

.section-title {
  &::after {
    display: block;
    content: "";
    height: $section-title-line-height;
    width: $section-title-line-width;
    background-color: $tertiary;
    margin-top: 1rem;
  }
}

.section-title--center {
  @include media-breakpoint-up(md) {
    text-align: center;

    &::after {
      margin-left: auto;
      margin-right: auto;
    }
  }
}
