.grid-gallery {
  position: relative;
}

.grid-gallery__container--one-image {
  .grid-gallery__img {
    aspect-ratio: 3/1;
  }
}

.grid-gallery__container--two-images {
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  gap: 0 1rem;
}

.grid-gallery__container--three-images {
  display: grid;
  grid-template-columns: minmax(0, 2fr) minmax(0, 1fr);
  gap: 1rem;
}

.grid-gallery__container--five-images {
  display: grid;
  grid-template-columns: minmax(0, 2fr) minmax(0, 1fr) minmax(0, 1fr);
  gap: 1rem;
}

.grid-gallery__img {
  cursor: pointer;
  object-fit: cover;
  border-radius: $border-radius;
  height: 100%;
  aspect-ratio: 3/2;

  &:first-of-type {
    grid-row: span 2;
  }

  &--hidden {
    display: none;
  }
}

.grid-gallery__count-badge {
  @include text-x-small;

  pointer-events: none;
  position: absolute;
  right: 1rem;
  bottom: 1rem;
  padding: 0.5rem;
  color: white;
  background-color: rgba(40, 40, 40, 0.75);
  border-radius: $border-radius;
}
