.sidebar {
  @include media-breakpoint-up(md) {
    height: 100%;
  }
}

.sidebar__cta-box {
  @include media-breakpoint-up(md) {
    position: sticky;
    top: 1.5rem;
  }
}
