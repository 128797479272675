.ticket-history--scan-container {
  @include media-breakpoint-up(sm) {
    max-width: 22rem;
  }
}

.ticket-history--video {
  max-height: 9rem;
  width: 100%;
  object-fit: cover;
}

.ticket-history--uses-remaining {
  display: inline-block;
  margin-left: 0.3rem;
  background-color: $tertiary;
  width: 1.6rem;
  height: 1.6rem;
  line-height: 1.7rem;
  border-radius: 0.8rem;
  color: $white;
  text-align: center;
}

.ticket-history--table-line {
  line-height: 1.8rem;
  color: #585858;

  &:not(:last-child) {
    border-bottom: 1px solid $divider;
  }
}

.ticket-history--table-time {
  width: 5rem;
}
