.upcoming-booking {
  color: #1f3d7c;
  overflow: hidden;
  background-color: rgba($primary, 0.25);

  @include media-breakpoint-up(md) {
    margin-top: -2rem;
  }

  .fa-tickets-perforated {
    margin-right: 0.5rem;

    @include media-breakpoint-up(lg) {
      font-size: 2rem;
      margin-right: 0;
    }
  }

  .btn {
    width: 100%;
    font-weight: initial;
    background-color: $white;

    &:hover {
      background-color: var(--bs-btn-hover-bg);
    }
  }
}

.upcoming-booking__reference {
  color: $secondary;
}

.upcoming-booking__tag,
.upcoming-booking__reference,
.upcoming-booking__detail,
.upcoming-booking__links {
  display: flex;
  flex-direction: column;
}

.upcoming-booking__tag,
.upcoming-booking__links {
  justify-content: center;
}

.upcoming-booking__tag {
  align-items: center;
  background-color: $primary;
  color: $white;
  font-size: 1.25rem;

  @include media-breakpoint-down(lg) {
    flex-direction: row;
    justify-content: unset;
  }
}

.upcoming-booking__ticket {
  @include media-breakpoint-up(lg) {
    font-size: 1.5rem;
  }
}

.upcoming-booking__icon {
  display: inline-block;
  width: 1.5rem;
  flex: 0 0 1.5rem;
}

.upcoming-booking__links {
  background-color: #f1f7fb;

  @include media-breakpoint-down(lg) {
    flex-direction: row;
  }
}
