@use "sass:map";

.pswp__content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.video {
  .video__thumbnail {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: $border-radius;
    position: relative;

    img {
      aspect-ratio: 3/2;
      object-fit: cover;
    }
  }

  .video__play-icon {
    color: $white;
    font-size: 3rem;
    position: absolute;

    @include media-breakpoint-up(md) {
      font-size: 5rem;
    }
  }
}
