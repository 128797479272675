@import "reset-css";

// =========================================================================
// reset-css overrides
// =========================================================================

em,
i {
  font-style: italic;
}

b,
strong {
  font-weight: 700;
}

// =========================================================================
// Border box for everybody
// =========================================================================

*,
*::before,
*::after {
  box-sizing: border-box;
  overflow-wrap: break-word;
}

// =========================================================================
// Images should be block level to remove unsued space below them
// =========================================================================

img {
  display: block;
  height: auto;
  width: 100%;
}

// =========================================================================
// Reset buttons to resemble links instead
// =========================================================================

button {
  -webkit-appearance: inherit;
  -webkit-box-align: inherit;
  background: transparent;
  border: none;
  color: inherit;
  cursor: pointer;
  display: inline;
  font: inherit;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  height: auto;
  line-height: inherit;
  margin: 0;
  padding: 0;
  text-align: inherit;
  text-transform: inherit;
  border-radius: 0;
  // fix for psuedo elements being misaligned in IE
  overflow: visible;
}

// =========================================================================
// Force anchors to scroll
// =========================================================================

html {
  scroll-behavior: smooth;
}

// =========================================================================
// Remove the margin in Firefox and Safari
// =========================================================================

input,
button,
select,
optgroup,
textarea {
  margin: 0;
}

// =========================================================================
// Style tables in the same way as bootstraps reboot css
// =========================================================================

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: $table-cell-padding-y;
  padding-bottom: $table-cell-padding-y;
  color: $table-caption-color;
  text-align: left;
}

// 1. Removes font-weight bold by inheriting
// 2. Matches default `<td>` alignment by inheriting `text-align`.
// 3. Fix alignment for Safari

th {
  font-weight: $table-th-font-weight; // 1
  text-align: inherit; // 2
  text-align: -webkit-match-parent; // 3
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  vertical-align: inherit;
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}
