.timetable {
  border: 1px solid $grey;
  overflow-x: auto;
  // For the scroll shadow border feature - https://jsfiddle.net/dt4qfj8k/
  background-image:
    // Shadows
    linear-gradient(to right, white, white),
    linear-gradient(to right, white, white),
    // Shadow covers
    linear-gradient(to right, rgba(0, 0, 0, 0.25), rgba(255, 255, 255, 0)),
    linear-gradient(to left, rgba(0, 0, 0, 0.25), rgba(255, 255, 255, 0));
  background-position: left center, right center, left center, right center;
  background-repeat: no-repeat;
  background-color: white;
  background-size: 20px 100%, 20px 100%, 10px 100%, 10px 100%;
  background-attachment: local, local, scroll, scroll;
}

.timetable__option-button {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.timetable--loading {
  background: none;
}

.timetable__container {
  border-bottom: 0;
  position: relative;
}

.timetable__row {
  display: flex;

  &:first-child {
    background-color: $grey;

    .timetable__col {
      background-color: $divider;
      border-right: 1px solid $grey;
      border-bottom: 1px solid $grey;
    }
  }
}

.timetable__col {
  border-right: 1px solid $divider;
  flex: 1 0 12.5rem;
  text-align: center;

  &:last-child {
    border-right: none !important;
  }
}

.timetable__col--active {
  background-color: $tertiary !important;
  color: $white;
}

.timetable__item {
  min-height: 2.6rem;
  border-bottom: 1px solid $divider;

  &:last-child {
    border-bottom: none;
  }
}

.timetable__seasonality {
  width: 6.25rem;
  color: $white;
}

// Super Saver
.timetable__seasonality--1 {
  background-color: $warning;
}

// Saver
.timetable__seasonality--2 {
  background-color: $tertiary;
}

// Regular
.timetable__seasonality--3 {
  background-color: $primary;
}

.timetable__stops-container {
  overflow-x: auto;
  overflow-y: visible;
  mask-image: linear-gradient(to right, transparent 0%, #000 5%, #000 95%, transparent 100%);
  -webkit-mask-image: linear-gradient(to right, transparent 0%, #000 5%, #000 95%, transparent 100%);
}

.timetable__stops-in-line {
  margin-top: 8rem;
}

.timetable__one-stop {
  cursor: pointer;
}

.timetable__stop-point {
  width: 1rem;
  height: 1rem;
  clip-path: circle(50%);
  background-color: $primary;
}

.timetable__stop-point--active {
  background-color: $tertiary;
}

.timetable__stop-name {
  min-height: 3rem;
  width: 7.5rem;
  transform-origin: 0% 50%;
  transform: rotate(-60deg);
}

.timetable__2-line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.timetable__2-line--active {
  color: $tertiary;
}

.timetable__stop-duration {
  flex-basis: 3.5rem;
  border-top: 1px solid $primary;
  transform: translateY(50%);
  max-width: 12rem;
}

.timetable__stop-duration-word {
  transform: translateX(-50%);
}

.timetable__annotation-cell {
  width: 6rem;
  height: 2rem;
}
