.alert {
  border-left-width: 0.5rem;
  display: flex;

  i:first-of-type {
    @include text-x-large;

    margin-right: 0.5rem;
  }

  &.alert-info {
    i {
      color: $info;
    }
  }

  &.alert-warning {
    i {
      color: $warning;
    }
  }
}
