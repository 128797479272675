@use "sass:list";

// ***********************
// Root
// ***********************

html {
  font-size: 100%;
}

@mixin body-text {
  font-family: $font-family-base;
  font-weight: $font-weight-normal;
  line-height: $line-height-base;
  font-size: 1rem;
  color: $body-color;
}

body,
.body-text {
  @include body-text();
}

// ***********************
// Small text
// ***********************

@mixin text-small {
  font-size: 0.9375rem;
}

@mixin text-x-small {
  font-size: 0.875rem;
}

@mixin text-xx-small {
  font-size: 0.8125rem;
}

@mixin text-xxx-small {
  font-size: 0.75rem;
}

.text-small {
  @include text-small;
}

.text-x-small {
  @include text-x-small;
}

.text-xx-small {
  @include text-xx-small;
}

.text-xxx-small {
  @include text-xxx-small;
}

// ***********************
// Large text
// ***********************

@mixin text-large {
  font-size: 1.125rem;
}

@mixin text-x-large {
  font-size: 1.25rem;
}

@mixin text-xx-large {
  font-size: 1.5rem;
}

@mixin text-xxx-large {
  font-size: 1.75rem;
}

.text-large {
  @include text-large;
}

.text-x-large {
  @include text-x-large;
}

.text-xx-large {
  @include text-xx-large;
}

.text-xxx-large {
  @include text-xxx-large;
}

// ***********************
// Heading
// ***********************

@mixin heading-shared {
  font-family: "Fira Sans Condensed", sans-serif;
  font-weight: 500;
  line-height: 1.2;
  transition: font-size 0.25s;
}

@mixin heading-one {
  @include heading-shared;

  font-size: 2rem;

  @include media-breakpoint-up(sm) {
    font-size: 2.5rem;
  }
}

@mixin heading-two {
  @include heading-shared;

  font-size: 1.75rem;

  @include media-breakpoint-up(sm) {
    font-size: 2rem;
  }
}

@mixin heading-three {
  @include heading-shared;

  font-size: 1.5rem;

  @include media-breakpoint-up(sm) {
    font-size: 1.75rem;
  }
}

@mixin heading-four {
  @include heading-shared;

  font-size: 1.375rem;

  @include media-breakpoint-up(sm) {
    font-size: 1.5rem;
  }
}

@mixin heading-five {
  @include heading-shared;

  font-size: 1.25rem;
}

@mixin heading-six {
  @include heading-shared;

  font-size: 1rem;
}

h1,
.h1 {
  @include heading-one;
}

h2,
.h2 {
  @include heading-two;
}

h3,
.h3 {
  @include heading-three;
}

h4,
.h4 {
  @include heading-four;
}

h5,
.h5 {
  @include heading-five;
}

h6,
.h6 {
  @include heading-six;
}

/*
Create a utility class using the BS reset-text mixin to allow
text with different line-heights, fonts etc back to the body text.
*/

.reset-text {
  @include reset-text;
}

@for $i from 1 through 6 {
  .line-clamp-#{$i} {
    @include line-clamp($i);
  }
}
