@use "sass:map";

.page-overlay-banner {
  position: relative;
  // We add the translate here to cover the bottom border of the header
  transform: translateY(-2px);
  z-index: map.get($zindex, banner-content);
  background-position: center;
  background-size: cover;
  background-image: var(--mobile-bg-image);
  aspect-ratio: 1.66/1;

  @include media-breakpoint-up(md) {
    background-image: var(--desktop-bg-image);
    aspect-ratio: 3.33/1;
  }
}

.page-overlay-banner__caption-text {
  position: absolute;
  right: 0;
  bottom: 25%;
  margin-right: 1rem;
  color: $white;
  background-color: rgba($black, 0.75);

  @include media-breakpoint-up(md) {
    bottom: 40%;
  }
}
