@use "sass:color";

$track-height: 2px;
$handle-size: 2rem;
$hover-colour:  color.scale($primary, $lightness: 50%);

.rc-slider {
  // Prevent margins from collapsing
  margin: 0 $handle-size * 0.5;
  display: flex;
  height: 0;
  padding: $handle-size * 0.5 0;
  width: auto;
}

.rc-slider-rail,
.rc-slider-track,
.rc-slider-step {
  height: $track-height;
}

.rc-slider-track {
  background-color: $primary;
}

.rc-slider-handle {
  width: $handle-size;
  height: $handle-size;
  margin-top: $handle-size * -0.5;
  top: 50%;
  border-color: $primary;

  &:hover {
    border-color: $hover-colour;
  }
}

.rc-slider-handle-dragging {
  box-shadow: 0 0 0 5px rgba($hover-colour, 0.25) !important;
}
