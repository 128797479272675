.content {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: $headings-margin-bottom;
  }

  p {
    margin-bottom: $paragraph-margin-bottom;
  }

  ul,
  ol {
    @include list-reset;
  }

  ol,
  ul,
  dl {
    margin-bottom: 1rem;
  }

  table {
    @extend .table;

    margin-bottom: 1rem;

    thead {
      background-color: $white;
    }

    th {
      font-weight: 700;
      color: $primary;
      border-bottom: 2px solid $primary;
    }
  }

  img {
    margin-bottom: 1rem;
  }

  figcaption {
    @include text-x-small;

    display: block;
    margin-bottom: 1.5rem;
    font-style: italic;
  }

  // Remove spacing from first and last elements

  & > *:last-child {
    margin-bottom: 0;
  }

  & > *:first-child {
    margin-top: 0;
  }
}

.content__list {
  @include list-reset;
}
