.vehicle-form {
  .vehicle-dimension-select {
    width: 6rem;
    margin-right: 0.5rem;
  }

  .vehicle-option__wrapper {
    color: $text-grey;
    max-width: 8.75rem;
  }

  .vehicle-template__divider {
    height: 0;
  }
}

.vehicle-form__dimension-number-field {
  width: 4.75rem;
}
