@use "sass:map";

$mobile-space: map.get($custom-spacers, 5);
$desktop-space: map.get($custom-spacers, 8);

.gap-primary {
  gap: $mobile-space;

  @include media-breakpoint-up(md) {
    gap: $desktop-space;
  }
}

.divider-primary {
  > * + * {
    border-top: 1px solid $divider;
    padding-top: $mobile-space;
    margin-top: $mobile-space;

    @include media-breakpoint-up(md) {
      padding-top: $desktop-space;
      margin-top: $desktop-space;
    }
  }
}

.py-primary {
  padding-top: $mobile-space;
  padding-bottom: $mobile-space;

  @include media-breakpoint-up(md) {
    padding-top: $desktop-space;
    padding-bottom: $desktop-space;
  }
}

.pt-primary {
  padding-top: $mobile-space;

  @include media-breakpoint-up(md) {
    padding-top: $desktop-space;
  }
}

.pb-primary {
  padding-bottom: $mobile-space;

  @include media-breakpoint-up(md) {
    padding-bottom: $desktop-space;
  }
}

.my-primary {
  margin-top: $mobile-space;
  margin-bottom: $mobile-space;

  &:has(> *:last-child .general-content-blocks > *:last-child.bg-light) {
    margin-bottom: 0;
  }

  @include media-breakpoint-up(md) {
    margin-top: $desktop-space;
    margin-bottom: $desktop-space;
  }
}

.mt-primary {
  margin-top: $mobile-space;

  @include media-breakpoint-up(md) {
    margin-top: $desktop-space;
  }
}

.mb-primary {
  margin-bottom: $mobile-space;

  @include media-breakpoint-up(md) {
    margin-bottom: $desktop-space;
  }
}
