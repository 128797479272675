.segment {
  &.segment--dark {
    background-color: $light;
    position: relative;
  }

  &.segment--dark--overlap {
    &::before {
      content: "";
      background: white;
      width: 100%;
      height: 4rem;
      position: absolute;
      bottom: 0;
    }
  }

  .segment__sold-out-toggle {
    display: flex;
    justify-content: flex-end;
  }

  .segment__prompt {
    display: flex;
    justify-content: space-between;
    margin-top: 0.75rem;
  }
}
