.breadcrumb-wrapper {
  background-color: $light;

  .breadcrumb {
    @extend .container-xxl;

    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }

  & .breadcrumb-item {
    a {
      color: $text-black;
    }

    & + .breadcrumb-item::before {
      @include fa-icon;

      font: var(--fa-font-regular);
      content: "\f054";
      float: none;
    }
  }
}

.breadcrumbs-from-template li:last-of-type {
  max-width: 24ch;
  text-overflow: ellipsis;
  overflow: hidden;
  text-wrap: nowrap;
}
