.saved-payment-methods {
  .saved-payment-methods__card,
  .saved-payment-methods__card--default {
    border: solid 1px $divider;
    border-radius: $border-radius;
    padding: 1rem;

    .saved-payment-methods__card-header {
      display: flex;
      justify-content: space-between;
      font-weight: 400;

      .supported-credit-cards__card {
        max-height: 1.5rem;
      }
    }
  }

  .saved-payment-methods__card--default {
    border-color: $primary;
  }
}
