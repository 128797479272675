$departures-height-desktop: 14.25rem;

.departure-list {
  border-radius: 0;
  border-bottom-left-radius: $border-radius-lg;
  border-bottom-right-radius: $border-radius-lg;

  .departure {
    border-bottom: 1px solid $divider;

    @include media-breakpoint-up(lg) {
      border-bottom: none;
    }
  }

  .departure-list__col:last-child {
    .departure {
      border-bottom: none;
    }
  }

  &.card {
    @include media-breakpoint-up(lg) {
      height: $departures-height-desktop;
    }
  }

  .card-header {
    border-radius: 0;
  }

  label {
    cursor: pointer;
  }
}

.departure__line {
  border-left: 1px dashed $white;
  height: 2.75rem;
}
