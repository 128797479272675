.expr3ss-jobs-widget-division-header {
  @extend .accordion-button;

  @include heading-one;

  font-size: 1.25rem !important;

  &:not(.unfolded) {
    &::after {
      -webkit-transform: none;
      transform: none;
    }
  }

  &.unfolded {
    &::after {
      -webkit-transform: rotate(-180deg);
      transform: rotate(-180deg);
    }
  }
}

.expr3ss-jobs-widget-division-header:not(:first-of-type) {
  margin-top: 1.25rem !important;
  padding-top: 1.25rem !important;
}

.expr3ss-jobs-widget-subtitle {
  @include body-text;

  font-size: 0.875rem !important;
}

.expr3ss-jobs-widget-job {
  @include media-breakpoint-down(md) {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
}

.expr3ss-jobs-widget-button {
  @include media-breakpoint-down(md) {
    width: 100% !important;
    order: 1;
    margin-bottom: 0.75rem;
  }
}
