$gap: 0.75rem;

.optional-extra {
  height: 100%;
  overflow: hidden;

  .image-hover {
    position: relative;
  }
}

.optional-extra__title {
  position: absolute;
  bottom: $gap;
  left: $gap;
  color: $white;
}

.optional-extra__details {
  padding: $gap;
  min-height: 8rem;
}

.optional-extra__footer {
  padding: $gap;
  border-top: 1px solid  $card-border-color;
  bottom: 0;
}

.optional-extra__footer--low {
  min-height: 9rem;
}

.optional-extra__status {
  min-height: 2.5rem;
}

.optional-extra__disabled {
  pointer-events: none;
  opacity: 0.5;
}
