.receipt-documents {
  .receipt-documents__pdf-icon {
    max-width: 24px;
  }

  .receipt-documents__ticket-icon,
  .receipt-documents__footer-logo {
    max-width: 8rem;
    margin: auto;
  }

  .receipt-documents__footer-logo {
    max-width: 18rem;
  }

  .card {
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.25);
  }

  .card-footer {
    background: $bt-charcoal;
  }

  .receipt-documents__card-title {
    color: $primary;
  }

  .hide {
    display: none;
  }

  .receipt-documents__pdf {
    display: flex;
    animation: fadeIn 600ms forwards ease;
    opacity: 0;

    @for $i from 1 through 4 {
      &:nth-child(#{$i}) {
        animation-delay: 65ms * $i;
      }
    }

    &:first-child {
      margin-top: 0;
    }
  }
}
